const ajaxLoadMore = () => {
  const buttonLoadMore = document.querySelector('.load-more');

  if (typeof (buttonLoadMore) != 'undefined' && buttonLoadMore != null) {
    buttonLoadMore.addEventListener('click', loadMorePosts);
  }
}

const loadMorePosts = () => {

  const cardList = document.querySelector('.card-list');

  if(!cardList){
    return;
  }

  $.ajax({
    type: 'POST',
    url: '/wp-admin/admin-ajax.php',
    dataType: 'html',
    data: {
      action: 'wsh_load_more_posts',
      current_page: cardList.dataset.page,
      filter: cardList.dataset.filter
    },
    success: function(res) {
      cardList.innerHTML += JSON.parse(res).data;
      cardList.dataset.page++;
      /* if(++cardList.dataset.page >= cardList.dataset.max){
        event.target.style.display = 'none';
      } */
    }
  });
}

const filterNavHandler = () => {

  const filterItems = document.querySelectorAll('[data-filter-nav] [data-filter]');

  if(!filterItems.length){
    return;
  }

  filterItems.forEach(item => {
    item.addEventListener('click', sendInitialRequest)
  });
}

const sendInitialRequest = (event) => {

  if(!event.target.hasAttribute('data-filter')){
    return;
  }

  const loader = document.querySelector('[data-loader]');
  if(loader){
    loader.classList.add('visible');
  }

  const cardList = document.querySelector('.card-list');

  if(!cardList){
    return;
  }

  cardList.dataset.filter = event.target.dataset.filter;
  cardList.dataset.page = 1;

  $.ajax({
    type: 'POST',
    url: '/wp-admin/admin-ajax.php',
    dataType: 'html',
    data: {
      action: 'wsh_load_more_posts',
      current_page: 0,
      filter: event.target.dataset.filter
    },
    success: function(res) {
      if(loader){
        loader.classList.remove('visible');
      }
      $('.card-list').fadeOut(300, function() {
        $(this).html(JSON.parse(res).data).fadeIn(300);
      });
    }
  });
}

filterNavHandler();


const onScrollLoad = () => {

  const cardList = document.querySelector('.card-list');

  if(!cardList){
    return;
  }

  let options = {
    root: null,
    rootMargin: '0px 0px 50px 0px',
    threshold: 1
  }

  const loader = document.querySelector('[data-loader]');
  if(!loader){
    return;
  }

  function handleIntersect(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if(cardList.dataset.page < cardList.dataset.max){
          loadMorePosts();
        }
      }
    });
  }

  let observer = new IntersectionObserver(handleIntersect, options);
  observer.observe(loader);
}

onScrollLoad();

const checkboxFix = () => {

  const checkboxes = document.querySelectorAll('form input[type="checkbox"]');

  if(!checkboxes.length){
    return;
  }

  checkboxes.forEach(checkbox => {
    checkbox.addEventListener('change', (event) => {
      if(event.target.type === 'checkbox'){
        const label = event.target.closest('label');
        if(!label){
          return;
        }
        
        if(event.target.checked){
          label.classList.add('checked');
        }else{
          label.classList.remove('checked');
        }
      }
    })
  })
}

checkboxFix();
const formSubmitHandler = () => {

  document.addEventListener( 'wpcf7invalid', highlightInvalidInputs, false );
  document.addEventListener( 'wpcf7mailsent', closeFormPopup, false );
  document.addEventListener( 'wpcf7mailsent', showSuccessPopup, false );
  document.addEventListener( 'wpcf7mailsent', resetFormStyling, false );
  resetInvalidTextInputsOnFocus();
  resetInvalidCheckboxesOnChange();
  btnCloseHandler();
  btnSubmitAttributesHandler();
}



const btnCloseHandler = () => {

  const btnsClose = document.querySelectorAll('[data-btn-close]'); 
  if(btnsClose.length === 0){
    return;
  }

  btnsClose.forEach(btn => {
    btn.addEventListener('click', (event) => {
      const currentPopup = event.target.closest('.popup');
      if(currentPopup){
        currentPopup.classList.remove('visible');
      }
      document.querySelector('body').classList.remove('scroll-lock');
    })
  })
}

const closeFormPopup = (event) => {
  
  const currentPopup = event.target.closest('.popup');
  if(!currentPopup){
    return;
  }
  currentPopup.classList.remove('visible');
}

const showSuccessPopup = (event) => {
  
  const formContainer = event.target.closest('[data-form-container]');
  if(!formContainer){
    return;
  }

  const successPopup = formContainer.nextElementSibling;
  if(successPopup){
    successPopup.classList.add('visible');
  }
}

const highlightInvalidInputs = (event) => {

  const invalidForm = event.target;

  console.log(event.detail.inputs);
  event.detail.inputs.forEach(input => {
    // excluding inputs with UTM/ref/etc. and leave only user inputs
    const regex = /_wpcf7|utm_|ref|gid/g;
    const found = input.name.match(regex);
    let inputHTMLTag = invalidForm.querySelector(`input[name="${input.name}"], textarea`);

    if(!found && input.value === ''){
      if(inputHTMLTag && inputHTMLTag.hasAttribute('aria-required', 'true')){
        inputHTMLTag.closest('.wpcf7-form-control-wrap').classList.add('invalid');
      }
    }else{
      inputHTMLTag.closest('.wpcf7-form-control-wrap').classList.add('valid');
    }
  });

  // checkbox inputs are not returned with event.detail.inputs
  // look for them as usual 
  const checkboxes = invalidForm.querySelectorAll('[type="checkbox"]');

  if(checkboxes.length !== 0){
    checkboxes.forEach(checkbox => {
      let checkboxWrapper = checkbox.closest('.checkbox-wrapper');
      if(checkboxWrapper && !checkbox.checked){
        //highlighting checkboxes
        checkboxWrapper.classList.add('invalid');
      }
    });
  }
}

const resetFormStyling = (event) => {

  const form = event.target;
  resetTextInputs(form);
  resetCustomCheckboxes(form);
}

const resetTextInputs = (form) => {
  const inputs = form.querySelectorAll('input, textarea');
  console.log(inputs);
  if(!inputs.length){
    return;
  }

  inputs.forEach(input => {
    let inputWrapper = input.closest('.wpcf7-form-control-wrap');
    if(inputWrapper){
      inputWrapper.classList.remove('valid');
    }
  });
}

const resetInvalidTextInputsOnFocus = () => {

  const inputs = document.querySelectorAll('.form input');

  if(!inputs.length){
    return;
  }

  inputs.forEach(input => {
    input.addEventListener('focus', (event) => {
      let inputWrapper = event.target.closest('.wpcf7-form-control-wrap');
      if(inputWrapper){
        inputWrapper.classList.remove('invalid');
      }
    });
  });
}

const resetCustomCheckboxes = (form) => {

  const checkboxLabels = form.querySelectorAll('.formBlock__checkbox label');
  if(!checkboxLabels.length){
    return;
  }
  checkboxLabels.forEach(label => {
    label.classList.remove('checked');
  });
}

const resetInvalidCheckboxesOnChange = () => {

  const checkboxes = document.querySelectorAll('.form [type="checkbox"]');
  if(!checkboxes.length){
    return;
  }

  checkboxes.forEach(checkbox => {
    checkbox.addEventListener('change', (event) => {

      let checkboxWrapper = event.target.closest('.checkbox-wrapper');
      if(!checkboxWrapper){
        return;
      }

      if(checkboxWrapper.classList.contains('invalid')){
        checkboxWrapper.classList.remove('invalid');
      }
    });
  });
}

const btnSubmitAttributesHandler = () => {

  const forms = document.querySelectorAll('form.wpcf7-form');

  if(!forms.length){
    return;
  }

  forms.forEach(form => {
    const submitBtn = form.querySelector('.js-submit');
    const selectValue = form.querySelector('input[name="service-name"]');
    const select = form.querySelector('.select');

    if(submitBtn && selectValue){
      submitBtn.setAttribute('data-service', selectValue.value);
    }

    if(select){
      select.addEventListener('click', (event) => {
        let selectItem = event.target.closest('.select__item');
        if(selectItem){
          submitBtn.setAttribute('data-service', selectItem.innerText);
        }
      })
    }

  });

}
window.addEventListener('load', ()=>{
  
  pageAgencyHandler();
  workshopPageHandler();
  consultingProjectsSlider();
  reviewsGalleryHandler();
  scrollToTopHandler();
  formSubmitHandler();
  ajaxLoadMore();
  telephoneMask();
  
});


const reviewsGalleryHandler = () => {
  
  const togglers = document.querySelectorAll('[data-review-toggle]');
  if(togglers.length === 0){
    return;
  }

  togglers.forEach(toggle => {
    toggle.addEventListener('click', showReviewsModalGallery);
  });

  const galleryContainer = document.querySelector('[data-gallery-container] .modal-gallery-container');
  if(!galleryContainer){
    return;
  }

  galleryContainer.addEventListener('click', hideReviewsModalGallery);
}

const showReviewsModalGallery = (event) => {

  const modalGallery = event.target.closest('[data-gallery-container]').querySelector('.modal-gallery-container');
  let currentSlideIndex = 0;

  if(!modalGallery){
    return;
  }
  
  if(event.target.hasAttribute('data-review-toggle')){
    modalGallery.classList.add('visible');
    document.querySelector('body').classList.add('scroll-lock');

    //set current slide
    currentSlideIndex = event.target.closest('.slick-slide').dataset.slickIndex;
    $('[data-modal-gallery]').slick('slickGoTo', currentSlideIndex);
  }
}

const hideReviewsModalGallery = (event) =>{

  if(event.target.classList.contains('btn-close') || event.target.classList.contains('modal-gallery-container')){
    const modalGallery = event.target.closest('[data-gallery-container]').querySelector('.modal-gallery-container');

    if(!modalGallery){
      return;
    }
    
    modalGallery.classList.remove('visible');
    document.querySelector('body').classList.remove('scroll-lock');
  }

}

modalGallerySlider();

function modalGallerySlider() {
  const slider = document.querySelector("[data-modal-gallery]");

  if (!slider) {
    return;
  }

  let status = document.querySelector('[data-modal-gallery-nav] .paging-info');

  $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    let i = (currentSlide ? currentSlide : 0) + 1;
    let totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
    let html = `<span class="current-slide">${i >= 10 ? i : '0' + i}</span>
                <span class="total-slides">${' / ' + totalSlides}`;
    status.innerHTML = html;
  });

  const slickOptions = {
    dots: false,
    slidesToShow: 1,
    infinite: true,
    arrows: false,
    variableWidth: false,
    adaptiveHeight: false,
    fade: true,
    cssEase: 'linear',
    speed: 10
  };

  $(slider).slick(slickOptions);

  $("[data-modal-gallery-nav] .slide-prev").click(function (e) {
    $(slider).slick("slickPrev");
  });

  $("[data-modal-gallery-nav] .slide-next").click(function (e) {
    $(slider).slick("slickNext");
  });

}
//------- AGENCY PAGE BEGIN -------

function agencyTeamSlider() {
  const slider = document.querySelector('[data-as_5-slider]');

  if (!slider) {
    return;
  }

  let status = document.querySelector('[data-as_5-slider-nav] .paging-info');

  $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    let i = (currentSlide ? currentSlide : 0) + 1;
    let totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
    let html = `<span class="current-slide">${i >= 10 ? i : '0' + i}</span>
                <span class="total-slides">${' / ' + totalSlides}`;
    status.innerHTML = html;
  });

  const slickOptions = {
    dots: false,
    centerMode: false,
    slidesToShow: 6,
    initialSlide: 0,
    infinite: true,
    arrows: false,
    variableWidth: false,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1601,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1141,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 921,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  $(slider).slick(slickOptions);

  $("[data-as_5-slider-nav] .slide-prev").click(function (e) {
    $(slider).slick("slickPrev");
  });

  $("[data-as_5-slider-nav] .slide-next").click(function (e) {
    $(slider).slick("slickNext");
  });
}

//************************************* */

function agencyClientsSlider() {
  const slider = document.querySelector("[data-as_6-slider]");

  if (!slider) {
    return;
  }

  let status = document.querySelector('[data-as_6-slider-nav] .paging-info');

  $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    let i = (currentSlide ? currentSlide : 0) + 1;
    let totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
    let html = `<span class="current-slide">${i >= 10 ? i : '0' + i}</span>
                <span class="total-slides">${' / ' + totalSlides}`;
    status.innerHTML = html;
  });

  const slickOptions = {
    dots: false,
    centerMode: false,
    slidesToShow: 3,
    initialSlide: 0,
    infinite: true,
    arrows: true,
    variableWidth: false,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 641,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  $(slider).slick(slickOptions);

  $("[data-as_6-slider-nav] .slide-prev").click(function (e) {
    $(slider).slick("slickPrev");
  });

  $("[data-as_6-slider-nav] .slide-next").click(function (e) {
    $(slider).slick("slickNext");
  });

}

//************************************* */

function agencyReviewsSlider() {
  const slider = document.querySelector("[data-reviews-slider]");

  if (!slider) {
    return;
  }

  let status = document.querySelector('[data-reviews-slider-nav] .paging-info');

  $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    let i = (currentSlide ? currentSlide : 0) + 1;
    let totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
    let html = `<span class="current-slide">${i >= 10 ? i : '0' + i}</span>
                <span class="total-slides">${' / ' + totalSlides}`;
    status.innerHTML = html;
  });

  const slickOptions = {
    dots: false,
    slidesToShow: 3,
    infinite: true,
    arrows: false,
    variableWidth: false,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  $(slider).slick(slickOptions);

  $("[data-reviews-slider-nav] .slide-prev").click(function (e) {
    $(slider).slick("slickPrev");
  });

  $("[data-reviews-slider-nav] .slide-next").click(function (e) {
    $(slider).slick("slickNext");
  });

}

function videoStartButtonHandler(){
  const videoStartButton = document.querySelector('[data-video-start-btn]');
  const video = document.querySelector('[data-video]');

  if(!videoStartButton || !video){
    return;
  }

  videoStartButton.addEventListener('click', (event) => {
    video.style.opacity = 1;
    video.setAttribute('controls', 'controls');
    video.play();
    event.target.closest('.video-poster').classList.add('hidden');
  });
}

function pageAgencyHandler(){
  agencyTeamSlider();
  agencyClientsSlider();
  agencyReviewsSlider();
  videoStartButtonHandler();
}

//------- AGENCY PAGE END ---------
// ------ CONSULTING PAGE START --------

function consultingProjectsSlider(){

    if(!window.matchMedia('(max-width: 768px)').matches){

        return;

    }


    const slider = document.querySelector('[data-workshop-projects]');

    if(!slider){
        return;
    }


    let status = document.querySelector('[data-workshop-projects-nav] .paging-info');


    $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {

        let i = (currentSlide ? currentSlide : 0) + 1;

        let totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;

        let html = `<span class="current-slide">${i >= 10 ? i : '0' + i}</span>

                <span class="total-slides">${' / ' + totalSlides}`;

        status.innerHTML = html;

    });



    const slickOptions = {

        dots: false,

        slidesToShow: 1,

        infinite: true,

        arrows: false,

        variableWidth: false,

        adaptiveHeight: false,
    };



    $(slider).slick(slickOptions);



    $("[data-workshop-projects-nav] .slide-prev").click(function (e) {

        $(slider).slick("slickPrev");

    });



    $("[data-workshop-projects-nav] .slide-next").click(function (e) {

        $(slider).slick("slickNext");

    });

}

// ------ CONSULTING PAGE END --------
$(document).ready(function () {
  const navToggler = document.querySelector(".nav-toggler");
  const navMenu = document.querySelector(".wrapper_nav");
  const navLinks = document.querySelectorAll(".mobile_menu a");

  navToggler.addEventListener("click", togglerClick);
  navLinks.forEach((elem) => elem.addEventListener("click", navLinkClick));

  function togglerClick() {
    navToggler.classList.toggle("toggler-open");
    navMenu.classList.toggle("open");
  }

  function navLinkClick() {
    if (navMenu.classList.contains("open")) {
      navToggler.click();
    }
  }

  function marquee() {
    const marqueeElementsDt = document.querySelectorAll("[data-marquee-dt]");
    if (marqueeElementsDt.length !== 0) {
      marqueeElementsDt.forEach((el) => animateMarquee(el, 20000));
    }
  }

  function animateMarquee(el, duration) {
    const innerEl = el.querySelector(".marquee__inner");
    const innerWidth = innerEl.offsetWidth;
    const cloneEl = innerEl.cloneNode(true);
    el.appendChild(cloneEl);

    let start = performance.now();
    let progress;
    let translateX;

    requestAnimationFrame(function step(now) {
      progress = (now - start) / duration;

      if (progress > 1) {
        progress %= 1;
        start = now;
      }

      translateX = innerWidth * progress;

      innerEl.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
      cloneEl.style.transform = `translate3d(-${translateX}px, 0 , 0)`;

      requestAnimationFrame(step);
    });
  }
  marquee();

  let $status = $(".slider_dots");
  let $slickElement = $(".slider");

  $slickElement.on(
    "init reInit afterChange",
    function (event, slick, currentSlide, nextSlide) {
      let i = (currentSlide ? currentSlide : 0) + 1;
      $status.text("0" + i + "/" + "0" + slick.slideCount);
      $status.text(
        (i > 9 ? i : "0" + i) +
          "/" +
          (slick.slideCount > 9 ? slick.slideCount : "0" + slick.slideCount)
      );
    }
  );

  $slickElement.slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    appendArrows: $(".slider_nav.desktop"),
    prevArrow:
      '<div class="slider-prev"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M8.57233 9.50008L12.4911 5.58133L11.3717 4.46191L6.3335 9.50008L11.3717 14.5382L12.4911 13.418L8.57233 9.49929L8.57233 9.50008Z" fill="#070707"/>\n' +
      "</svg>\n</div>",
    nextArrow:
      '<div class="slider-next"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M10.4277 9.50008L6.50892 5.58133L7.62834 4.46191L12.6665 9.50008L7.62834 14.5382L6.50892 13.418L10.4277 9.49929L10.4277 9.50008Z" fill="#070707"/>\n' +
      "</svg>\n</div>",

    responsive: [
      {
        breakpoint: 768,
        settings: {
          appendArrows: $(".slider_nav.mobile"),
        },
      },
    ],
  });

  let phoneFieldValid = document.querySelector('input[type="tel"]');
  phoneFieldValid.addEventListener("keypress", (e) => {
    if (!/\d/.test(e.key)) {
      e.preventDefault();
    }
  });

  function postsSlider(){
    if (window.innerWidth <= 1100) {

      const options = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false
      };
  
      let postsStatus = document.querySelector('[data-posts-slider-nav] .paging-info');
  
      $(".wrapper_posts").on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        let i = (currentSlide ? currentSlide : 0) + 1;
        let totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
        let html = `<span class="current-slide">${i >= 10 ? i : '0' + i}</span>
                    <span class="total-slides">${' / ' + totalSlides}`;
        postsStatus.innerHTML = html;
      });

      $(".wrapper_posts").slick(options);

      $("[data-posts-slider-nav] .slide-prev").click(function (e) {
        $(".wrapper_posts").slick("slickPrev");
      });
  
      $("[data-posts-slider-nav] .slide-next").click(function (e) {
        $(".wrapper_posts").slick("slickNext");
      });
    }
  }

  postsSlider();

  $(".language_toggle").click(function () {
    $(".language_menu").toggleClass("open");
    $(".language_block_mobile").toggleClass("open");
  });
  
  $(".language_block_mobile .language_toggle").text($('.language_block_mobile .current-lang > a').text());

  //select

  jQuery(($) => {
    $(".select").on("click", ".select__head", function () {
      if ($(this).hasClass("open")) {
        $(this).removeClass("open");
        $(this).next().fadeOut();
      } else {
        $(".select__head").removeClass("open");
        $(".select__list").fadeOut();
        $(this).addClass("open");
        $(this).next().fadeIn();
      }
    });

    $(".select").on("click", ".select__item", function () {
      $(".select__head").removeClass("open");
      $(this).parent().fadeOut();
      $(this).parent().prev().html($(this).html());
      $(".select__input").val($(this).text());
    });

    $(document).click(function (e) {
      if (!$(e.target).closest(".select").length) {
        $(".select__head").removeClass("open");
        $(".select__list").fadeOut();
      }
    });
  });

  //popup email

  $("#popup_email").click((e) => {
    $("[data-email-popup]").addClass('visible');
    $('body').addClass('scroll-lock');
  });
/* 
  $(".close_popup").click((e) => {
    $(".posts_popup").hide();
  }); */

});

// ------ WORKSHOP PAGE START --------

const radioGroupHandler = () => {

  if(!window.matchMedia('(min-width: 801px)').matches){
    return;
  }

  const radios = document.querySelectorAll('#workshop-format input[type="radio"]');
  const radioWrapper = document.querySelector('.radio-wrapper');
  const radioContentItems = document.querySelectorAll('.radio-item .radio-content__inner');
  
  if(!radioWrapper || radioContentItems.length === 0 || radios.length === 0){
    return;
  }

  const initialRadioWrapperHeight = radioWrapper.getBoundingClientRect().height;
  radioWrapper.dataset.initialHeight = initialRadioWrapperHeight;

  const radioContentHeights = [];

  radioContentItems.forEach((item, index) => {
    radioContentHeights.push(item.offsetHeight);

    radios[index].dataset.contentHeight = item.offsetHeight;
    radios[index].addEventListener('change', setRadioWrapperHeight);

    if(radios[index].checked){
      radioWrapper.style.height = initialRadioWrapperHeight + item.offsetHeight + 'px';
    }
  });

}

const setRadioWrapperHeight = (event) => {
  const radioWrapper = document.querySelector('.radio-wrapper')
  radioWrapper.style.height = parseInt(radioWrapper.dataset.initialHeight) + parseInt(event.target.dataset.contentHeight) + 'px';
}

const workshopCardsSlider = () => {

  if(!window.matchMedia('(max-width: 1120px)').matches){
    return;
  }

  const slider = document.querySelector('[data-workshop-cards]');
  if(!slider){
    return;
  }

  let status = document.querySelector('[data-workshop-cards-nav] .paging-info');

  $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    let i = (currentSlide ? currentSlide : 0) + 1;
    let totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
    let html = `<span class="current-slide">${i >= 10 ? i : '0' + i}</span>
                <span class="total-slides">${' / ' + totalSlides}`;
    status.innerHTML = html;
  });

  const slickOptions = {
    dots: false,
    slidesToShow: 2,
    infinite: true,
    arrows: false,
    variableWidth: false,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          initialSlide: 1
        },
      }
    ],
  };

  $(slider).slick(slickOptions);

  $("[data-workshop-cards-nav] .slide-prev").click(function (e) {
    $(slider).slick("slickPrev");
  });

  $("[data-workshop-cards-nav] .slide-next").click(function (e) {
    $(slider).slick("slickNext");
  });
}

const cardsPopupHandler = () => {

  const cards = document.querySelector('[data-workshop-cards]');
  if(!cards){
    return;
  }

  const btnsClose = document.querySelectorAll('[data-btn-close]');
  if(btnsClose.length !== 0){
    btnsClose.forEach(btn => {
      btn.addEventListener('click', hideCardsPopup);
    })
  }

  cards.addEventListener('click', showCardsPopup);
}

const showCardsPopup = (event) => {
  
  if(event.target.tagName !== 'BUTTON'){
    return;
  }

  const id = event.target.dataset.id;
  const popup = document.querySelector('.popup');
  if(!popup){
    return;
  }

  // setting form options according to clicked card
  if(id !== null && id !== undefined){
    setCurrentOption(id);
  }
  
  document.querySelector('body').classList.add('scroll-lock');
  popup.classList.add('visible');
}

const setCurrentOption = (id) => {
  
  let current = $(`.workshop-form [data-id="${id}"]`);

  $(".workshop-form .select__input").val($(current).text());
  $(".workshop-form .select__head").html($(current).html());

  if($(current).data('type') === 'video'){
    $(".workshop-form .form-hint").addClass("visible");
    $(".workshop-form [data-name='your-phone']").addClass("hidden");
  }else{
    $(".workshop-form .form-hint").removeClass("visible");
    $(".workshop-form [data-name='your-phone']").removeClass("hidden");
  }
}

const hideCardsPopup = (event) => {
  
  const popup = event.target.closest('.popup');
  if(!popup){
    return;
  }

  document.querySelector('body').classList.remove('scroll-lock');
  popup.classList.remove('visible', 'on-success');
}

const toggleFormInputs = () => {

  const formHint = document.querySelector('.workshop-form .form-hint');
  const phoneInput = document.querySelector('.workshop-form [data-name="your-phone"]');
  
  if(!formHint || !phoneInput){
    return;
  }
  
  $(".workshop-form .select").on("click", ".select__item", function () {
    if($(this).data('type') === 'video'){
      $(".workshop-form .form-hint").addClass("visible");
      $(".workshop-form [data-name='your-phone']").addClass("hidden");
    }else{
      $(".workshop-form .form-hint").removeClass("visible");
      $(".workshop-form [data-name='your-phone']").removeClass("hidden");
    }
  });
}

const uncheckRadioBtnsOnMobile = () => {
  if(!window.matchMedia('(max-width: 800px)').matches){
    return;
  }

  const firstRadioBtn = document.querySelector('#workshop-format .radio-wrapper .radio-item:first-child input[type="radio"]');
  if(!firstRadioBtn){
    return;
  }
  firstRadioBtn.checked = false;
}

const workshopPageHandler = () => {
  radioGroupHandler();
  workshopCardsSlider();
  toggleFormInputs();
  cardsPopupHandler();
  uncheckRadioBtnsOnMobile();
}
// ------ WORKSHOP PAGE END --------


const scrollToTopHandler = () => {

  totopBtnToggle();

  $(".totop").click(function() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
  });

}

const totopBtnToggle = () => {

  const totopBtn = document.querySelector('.totop');
  if(totopBtn) {
    window.addEventListener('scroll', () => {
      if(window.scrollY > 500){
        totopBtn.classList.add('visible');
      }else{
        totopBtn.classList.remove('visible');
      }
    })
  };
}

// --- adding smooth anchor scroll 

$(document).on('click', 'a[href^="#"]', function (event) {
  event.preventDefault();

  $('html, body').animate({
    scrollTop: $($.attr(this, 'href')).offset().top
  }, 500);
});
function telephoneMask() {
  const telephoneInputs = document.querySelectorAll('input[type="tel"]');

  if (telephoneInputs.length !== 0) {
    telephoneInputs.forEach((telephoneInput) => {
      window.intlTelInput(telephoneInput, {
        initialCountry: "auto",
        separateDialCode: true,
        customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
          if(selectedCountryData.dialCode === '380'){
            selectedCountryPlaceholder = '50 123 4567'
          }
          return selectedCountryPlaceholder;
        },
        geoIpLookup: (callback) => {
          fetch("https://ipapi.co/json")
            .then((res) => res.json())
            .then((data) => callback(data.country_code))
            .catch(() => callback("us"));
        },
        utilsScript:
          "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
      });

      /* const telephoneInputWidth = getComputedStyle(telephoneInput).width;
      const countryList = telephoneInput
        .closest(".iti ")
        .querySelector(".iti__country-list");

      countryList.style.width = telephoneInputWidth; */
    });
  }
}